export default [
    /*{
        component: 'CNavItem',
        name: 'Panel de control',
        access: 'modelos/panel',
        to: '/dashboard',
        icon: 'fas fa-chart-pie',
    },*/
    {
        component: 'CNavGroup',
        name: 'Modelos de datos',
        access: 'modelos/ver-modelos',
        to: '',
        icon: 'fas fa-database',
        items: [
            {
                component: 'CNavItem',
                name: 'Listado de modelos',
                access: 'modelos/ver-modelos',
                to: '/modelos',
            },
        ],
    },
    /*{
        component: 'CNavGroup',
        name: 'Administración',
        access: 'Administración',
        to: '',
        icon: 'fas fa-th-large',
        items: [
            {
                component: 'CNavItem',
                name: 'Archivadores',
                access: 'admin/archivadores',
                to: '/admin/archivadores',
            },
            {
                component: 'CNavItem',
                name: 'Formularios',
                access: 'admin/formularios',
                to: '/admin/formularios',
            },
            {
                component: 'CNavItem',
                name: 'Flujos de trabajo',
                access: 'admin/flujos',
                to: '/admin/flujos',
            },
        ],
    },*/
    {
        component: 'CNavGroup',
        name: 'Usuarios',
        to: '',
        icon: 'fas fa-user',
        access: 'Usuarios',
        items: [
            {
                component: 'CNavItem',
                access: 'users/admin',
                name: 'Administrar usuarios',
                to: '/usuarios/listado',
            },
            {
                component: 'CNavItem',
                access: 'users/role/admin',
                name: 'Administrar roles',
                to: '/usuarios/roles/listado',
            },
        ],
    },
    /*{
        component: 'CNavGroup',
        name: 'Configuración',
        access: 'Configuración',
        to: '',
        icon: 'fas fa-cog',
        items: [
            {
                component: 'CNavItem',
                access: 'configuration',
                name: 'Administrar',
                to: '/configuration/sistema',
            },
        ],
    },*/
]
