import {h, resolveComponent} from 'vue'
import {createRouter, createWebHashHistory} from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from "@/store";
import {config} from "@/config";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: DefaultLayout,
        redirect: '/dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () =>
                    import('@/views/windows/modelos/Panel.vue'),
            },
            {
                name: 'Modelos de datos',
                path: '/modelos',
                component: () => import('@/views/windows/modelos/Modelos.vue'),
            },
            {
                name: 'Modelo M1',
                path: '/modelos/m1',
                component: () => import('@/views/windows/modelos/M1.vue'),
            },
            {
                name: 'Modelo M1-2',
                path: '/modelos/m1_2',
                component: () => import('@/views/windows/modelos/M1_2.vue'),
            },
            {
                name: 'Modelo M2',
                path: '/modelos/m2',
                component: () => import('@/views/windows/modelos/M2.vue'),
            },
        ],
    },
    {
        path: '/admin',
        name: 'Administración',
        component: DefaultLayout,
        redirect: '',
        children: [

        ],
    },
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: DefaultLayout,
        redirect: '/usuarios/listado',
        children: [
            {
                name: 'Listado usuarios',
                path: '/usuarios/listado',
                component: () => import('@/views/windows/usuarios/UsuariosListado.vue'),
            },
            {
                name: 'Editar usuario',
                path: '/usuarios/edit/:id',
                component: () => import('@/views/windows/usuarios/UsuariosEditar.vue'),
            },
            {
                name: 'Listado roles',
                path: '/usuarios/roles/listado',
                component: () => import('@/views/windows/usuarios/RolesListado.vue'),
            },
            {
                name: 'Editar rol',
                path: '/usuarios/roles/edit/:id',
                component: () => import('@/views/windows/usuarios/RolesEditar.vue'),
            },
        ],
    },
    /*{
        path: '/configuration',
        name: 'Configuración',
        component: DefaultLayout,
        redirect: '/configuration/system',
        children: [
            {
                name: 'Configuración de sistema',
                path: '/configuration/sistema',
                component: () => import('@/views/windows/configuracion/NotificacionEditar.vue'),
            },
        ],
    },*/
    {
        path: '/',
        redirect: '/404',
        component: {
            render() {
                return h(resolveComponent('router-view'))
            },
        },
        children: [
            {
                path: '/404',
                name: 'Page404',
                component: () => import('@/views/pages/Page404'),
                meta: {
                    public: true
                }
            },
            {
                path: '/form-p/:formSlug',
                name: 'FormularioPublico',
                component: () => import('@/views/windows/formularios/Publico.vue'),
                meta: {
                    public: true
                }
            },
            {
                path: '/login',
                name: 'Login',
                component: () => import('@/views/pages/Login'),
                meta: {
                    public: true
                }
            },
            /*
            {
                path: '/reset-password',
                name: 'ResetPassword',
                component: () => import('@/views/pages/RecuperarPass.vue'),
                meta: {
                    public: true
                }
            },
            {
                path: '/reset-my-password/:token',
                name: 'ResetPasswordWToken',
                component: () => import('@/views/pages/RecuperarPass.vue'),
                meta: {
                    public: true
                }
            },
            {
                path: '/auth/manager',
                name: 'AuthManager',
                component: () => import('@/views/pages/AuthManager'),
                meta: {
                    public: true
                }
            }*/
            /*{
                path: '/register',
                name: 'Register',
                component: () => import('@/views/pages/Register'),
                meta: {
                    public: true
                }
            },
            ,*/
        ],
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
})

router.beforeEach((to, from, next) => {
    const rutaPublica = to.matched.some(record => record.meta.public);

    /*console.log(to);
    console.log(rutaPublica);*/
    if (to.path === '/404' || to.path === '/login' || to.path.includes('form-p/')) {
        next();
    }
    else {
        store.dispatch('ValidateLogin', {
            callback: (response) => {

                if (typeof response.logged === 'undefined') {
                    next('/login');
                }
                else {
                    if (!rutaPublica) {
                        // valido si estoy logueado
                        //console.log(store.getters.authLogged);
                        if (store.getters.authLogged) {
                            next();
                        }
                        else {
                            window.location.href = '/#/login';
                            //next('login');
                        }
                    }
                    else {
                        next();
                    }
                }
            }
        });
    }

    /*console.log(rutaPublica);
    return false;*/
    //const isLoginClient = to.matched.some(record => record.meta.loginClient);


});

export default router
